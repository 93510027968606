<script>
import test from "@/data/test";

export default {
  data: () => ({
    test: null,
    page: -1,
    currentPage: 0,
    hotzoneAddNum: 0,
    hotzoneBaseNum: 0,
    showTips: false,
    lastid: 9999,
    showJiantou: 9999,
    reloading: false,
  }),
  watch: {
    page() {
      try {
        window.navigator.vibrate(200);
      } catch (error) {
        //
      }
      if (this.page >= 0) this.$nextTick(() => this.testimgInited());
      if (this.page > 0 && this.page === this.test.data.length - 1) {
        setTimeout(() => {
          if (this.page !== this.test.data.length) this.page++;
        }, 3000);
      }
    },
  },
  mounted() {
    if (this.$route.query?.page) {
      this.page = Number(this.$route.query?.page);
      this.currentPage = Number(this.$route.query?.currentPage);
      this.$nextTick(() => {
        setTimeout(() => {
          this.testimgInited();
        }, 500);
      });
    }

    this.test = test[this.$route.query?.id];
    console.warn(this.test);
    this.lastid = test.length - 1;
    try {
      window.navigator.vibrate(1000);
    } catch (error) {
      //
    }

    window.addEventListener(
      "orientationchange",
      () => {
        // if (this.page >= 0) {
        //   this.$nextTick(() => {
        //     this.testimgInited();
        //   });
        // }

        let id = this.$route.query?.id;
        let menu = this.$route.query?.menu;
        let page = this.page;
        let currentPage = this.currentPage;

        this.reloading = true;
        this.$router.push({
          path: "/test",
          query: { id: id, menu: menu, page: page, currentPage: currentPage },
        });
        setTimeout(() => {
          location.reload(true);
        }, 320);
      },
      false
    );
  },
  methods: {
    fail() {
      console.log("点错了");
    },
    back() {
      let menu = this.$route.query?.menu;
      this.$router.push({
        path: "/menu",
        query: { type: ++menu },
      });
    },
    next() {
      let id = this.$route.query?.id;
      let menu = this.$route.query?.menu;
      this.$router.push({
        path: "/test",
        query: { id: ++id, menu: menu },
      });
    },
    testimgInited() {
      // if (this.hotzoneAddNum) return;
      const dom = document.getElementsByClassName("testimg")[this.page];
      const clientHeight =
        document.body.clientHeight > document.body.clientWidth
          ? document.body.clientHeight
          : document.body.clientWidth;
      //  const clientHeight =
      //   window.screen.availHeight > window.screen.availWidth
      //     ? window.screen.availHeight
      //     : window.screen.availWidth;
      console.log(dom.offsetWidth);
      this.hotzoneAddNum = (clientHeight - dom.offsetWidth) * 0.5;
      this.hotzoneBaseNum = dom.offsetWidth * 0.01;
      console.log(this.hotzoneBaseNum);
    },
    toFail() {
      if (this.page < this.test.data.length - 1) {
        this.currentPage = this.page;
        return (this.page = -2);
      } else return (this.page = this.test.data.length);
    },
    cuowule() {
      this.page = this.currentPage;
      this.showJiantou = this.page;
    },
  },
};
</script>

<template>
  <div
    v-if="reloading"
    style="position: fixed; top: 0; left: 0; width: 100vw; height: 100vh; background: #fff"
  ></div>
  <div class="test">
    <div class="content" v-if="test">
      <!-- 起始页 -->
      <div class="start" v-show="-1 === page">
        <img
          src="~@/assets/test-start-back.png"
          style="position: absolute;left: 0vmin;top: 0vmin;width:24vmin"
          @click="back"
        />
        <div style="font-size: 5.6vmin;font-weight: bold;padding-bottom:9vmin">
          {{ test.title }}
        </div>
        <div
          style="font-size: 4vmin;padding-bottom:6vmin;width: calc(56vmax);text-align: center"
        >
          {{ test.content }}
        </div>
        <div
          style="font-size: 3vmin;padding-bottom:12vmin;width: calc(56vmax);text-align: center"
        >
          {{ test.tips }}
        </div>
        <img
          src="~@/assets/test-start-button.png"
          style="width:60vmin;"
          @click="page++"
        />
      </div>
      <!-- 答题数据页 -->
      <div
        v-for="(d, index) in test.data"
        :key="index"
        class="data"
        v-show="page === index"
      >
        <img
          :src="d.img"
          style="height: 100%"
          @click.stop="toFail"
          class="testimg"
        />
        <div
          v-for="i in [1, 2, 3]"
          :key="i"
          class="hotzone"
          :style="{
            left: `${hotzoneAddNum + hotzoneBaseNum * d['x' + i]}px`,
            top: `${hotzoneBaseNum * d['y' + i]}px`,
            width: `${hotzoneBaseNum * d['w' + i]}px`,
            height: `${hotzoneBaseNum * d['h' + i]}px`,
            'pointer-events': d['goto' + i] < 0 ? 'none' : 'auto',
          }"
          @click.stop="page = d['goto' + i]"
        ></div>
        <div
          v-show="index === showJiantou"
          class="jiantou"
          :style="{
            left: `${hotzoneAddNum + hotzoneBaseNum * d['xt']}px`,
            top: `${hotzoneBaseNum * d['yt']}px`,
            width: `${hotzoneBaseNum * 8}px`,
            height: `${hotzoneBaseNum * 5.5}px`,
          }"
        >
          <img
            src="~@/assets/jiantou.png"
            :style="{
              transform: `rotate(${d['rt']}deg)`,
            }"
          />
        </div>
      </div>
      <!-- 答题通过页 -->
      <div class="tongguan" v-show="test.data.length === page">
        <div class="alert" v-if="$route.query?.id != lastid">
          <img src="~@/assets/test-wanchengceshi.png" style="height: 88vmin" />
          <img
            src="~@/assets/test-querenfanhui.png"
            style="position: absolute;left: 8vmin;bottom: 10vmin;width:34.7vmin"
            @click="$router.back()"
          />
          <img
            src="~@/assets/test-xiayizhang.png"
            style="position: absolute;right: 8vmin;bottom: 10vmin;width:34.7vmin"
            @click="next"
          />
        </div>
        <div class="alert" v-else>
          <img src="~@/assets/test-wanchengceshi.png" style="height: 88vmin" />
          <img
            src="~@/assets/test-querenfanhui.png"
            style="position: absolute;left: 50%;bottom: 10vmin;width:34.7vmin;margin-left: -17.35vmin;"
            @click="$router.back()"
          />
        </div>
      </div>
      <!-- 答题失败页 -->
      <div class="tongguan" v-show="-2 === page" @click="cuowule">
        <div class="alert">
          <img src="~@/assets/test-caozuocuowu.png" style="height: 88vmin" />
          <!-- <img
            src="~@/assets/test-congtoukaishi.png"
            style="position: absolute;left: 50%;bottom: 10vmin;width:50vmin;margin-left: -25vmin;"
            @click="cuowule"
          /> -->
        </div>
      </div>
    </div>
    <!-- 悬浮提示 -->
    <img
      v-show="page >= 0 && page < test.data.length"
      src="@/assets/test-tips.png"
      style="position: fixed;left: 2vmin;top: 84vmin;width:12vmin;height:12vmin;z-index:100;"
      @click="showTips = !showTips"
      class="scale"
    />
    <!-- 悬浮提示层 -->
    <template v-if="test">
      <div v-show="showTips" class="tips" @click.stop="showTips = false">
        <div @click.stop="showTips = true">
          <div
            style="#000000;font-size:5.6vmin;font-weight:bold;padding-bottom:4vmin"
          >
            {{ test.title }}
          </div>
          <div style="text-align:center;line-height:2">
            {{ test.content }}
          </div>
          <div style="text-align:center;line-height:2">
            {{ test.tips }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style>
.tips {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vmax;
  height: 100vmin;
  background: rgba(0, 0, 0, 0.56);
  display: flex;
  align-items: center;
  justify-content: center;
}

.tips > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 72vmax;
  height: 72vmin;
  background: #ffffff;
  border-radius: 1.8vmin;
  padding: 12vmin 12vmin;
  overflow-y:scroll;
}

.alert {
  position: relative;
}

.tongguan {
  height: 100%;
  width: 100%;
  background-image: url("~@/assets/test-start-bg.jpg");
  background-color: #cce5e2;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.data {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #006340; */
  background: #eff8ff;
}

.hotzone {
  position: absolute;
  z-index: 1;
}

.jiantou {
  position: absolute;
  z-index: 2;
  pointer-events: none;
  animation: scale 0.8s infinite ease-in-out;
}

.jiantou > img {
  width: 100%;
  height: 100%;
}

.start {
  height: 100%;
  width: 100%;
  background-image: url("~@/assets/test-start-bg.jpg");
  background-color: #cce5e2;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  height: 100vmin;
  width: 100vmax;
  position: relative;
}

.content > * {
  height: 100%;
  width: 100%;
}

@media screen and (orientation: portrait) {
  .test {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    width: 100vh;
    height: 100vh;
    /*去掉overflow 微信显示正常，但是浏览器有问题，竖屏时强制横屏缩小*/
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }
}

@media screen and (orientation: landscape) {
  .test {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
}

#app {
  overflow: hidden;
}

/* 放大缩小吸引人点击的动画 */
.scale {
  animation: scale 0.8s infinite ease-in-out;
}

@keyframes scale {
  0% {
    transform: scale3d(0.8, 0.8, 0.8);
  }

  50% {
    transform: scale3d(1, 1, 1);
  }

  100% {
    transform: scale3d(0.8, 0.8, 0.8);
  }
}
</style>

import login from './login.json';
import ct1 from './ct1.json';
import ct2 from './ct2.json';
import ct3 from './ct3.json';
import ct4 from './ct4.json';
import jt1 from './jt1.json';
import jt2 from './jt2.json';
import jt3 from './jt3.json';

export default [
    login,
    jt1,
    jt2,
    jt3,
    ct1,
    ct2,
    ct3,
    ct4,
]